import React from "react";
import { closeIcon } from "../../Base/SVG";

export default function ClashMod({ setModal }) {
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modal__inner-title">
        <h3 className="sm">Clash of Champions</h3>
        <h6 className="sm">Instructions</h6>
      </div>
      <div className="modal__inner-head">
        <h3 className="sm">Who will finish ahead ?</h3>
        <p>
          Select one driver per team. If your picked driver finishes ahead of
          the unselected teammate, you earn one point.
        </p>
      </div>
      <div className="modal__inner-steps">
        <div className="modal__inner-step">
          <h6>Step 1</h6>
          <p>
            Choose the driver per team who you think is going to be ahead of his
            teammate at the end of the race.
          </p>
        </div>
        <div className="modal__inner-step">
          <h6>Step 2</h6>
          <p>
            Validate your selection through a free transaction on our
            proprietary blockchain.
          </p>
        </div>
      </div>
    </>
  );
}
