import * as React from 'react';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

// const vertical = "bottom"
// const horizontal = "right"

// const message = {}

export default function PositionedSnackbar({open, setOpen, message , vertical, horizontal, success}) {
//   const [state, setState] = React.useState({
//     open: false,
//     vertical: 'top',
//     horizontal: 'center',
//   });
//   const { vertical, horizontal, open } = state;

//   const handleClick = (newState) => () => {
//     setState({ open: true, ...newState });
//   };

  const handleClose = () => {
    setOpen(false);
  };

//   const buttons = (
//     <React.Fragment>
//       <Button
//         onClick={handleClick({
//           vertical: 'top',
//           horizontal: 'center',
//         })}
//       >
//         Top-Center
//       </Button>
//       <Button
//         onClick={handleClick({
//           vertical: 'top',
//           horizontal: 'right',
//         })}
//       >
//         Top-Right
//       </Button>
//       <Button
//         onClick={handleClick({
//           vertical: 'bottom',
//           horizontal: 'right',
//         })}
//       >
//         Bottom-Right
//       </Button>
//       <Button
//         onClick={handleClick({
//           vertical: 'bottom',
//           horizontal: 'center',
//         })}
//       >
//         Bottom-Center
//       </Button>
//       <Button
//         onClick={handleClick({
//           vertical: 'bottom',
//           horizontal: 'left',
//         })}
//       >
//         Bottom-Left
//       </Button>
//       <Button
//         onClick={handleClick({
//           vertical: 'top',
//           horizontal: 'left',
//         })}
//       >
//         Top-Left
//       </Button>
//     </React.Fragment>
//   );

  return (
    <div>
      {/* {buttons} */}
      <Snackbar
        className="snack-bar"
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        // message={
        //   <ErrorIcon /> "WRONG NETWORK"}
        children= {
          <div className="message">
            {success?<CheckCircleRoundedIcon className="message-icon-success"/> : <ErrorIcon className="message-icon"/>} <span className="message-text">{message}</span>
          </div>
        }
        key={vertical + horizontal}
        severity="error"
        autoHideDuration={10000}
      />
    </div>
  );
}