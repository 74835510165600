import React from "react";
import { facebookIcon, twitterIcon, youtubeIcon } from "../../Base/SVG";

export default function Info({ race }) {
  return (
    <div className="info">
      <div className="info__inner">
        <div className="info__title">
          <p className="sm">About the race</p>
        </div>
        <div className="info__circuit">
          <h5>Circuit Layout</h5>
          <div className="info__circuit-image">
            <img src={process.env.PUBLIC_URL + race?.map} alt="map" />
          </div>
          <div className="info__circuit-row">
            <img src={process.env.PUBLIC_URL + race?.flag} alt="flag" />
            <h6 className="sm">
              {race?.info_circuit
                }
            </h6>
          </div>
        </div>
        <div className="info__stats">
          <h5>Statistics</h5>
          <div className="info__stats-items">
            <div className="info__stats-item">
              <p className="xsm">First GP</p>
              <h6 className="sm">{race?.first_gp}</h6>
            </div>
            <div className="info__stats-item">
              <p className="xsm">Number of Laps</p>
              <h6 className="sm">{race?.number_of_laps}</h6>
            </div>
            <div className="info__stats-item">
              <p className="xsm">Circuit Length</p>
              <h6 className="sm">{race?.circuit_length}</h6>
            </div>
            <div className="info__stats-item">
              <p className="xsm">Race Distance</p>
              <h6 className="sm">{race?.race_distance}</h6>
            </div>
            <div className="info__stats-item">
              <p className="xsm">Lap Record</p>
              <h6 className="sm">{race?.lap_record}</h6>
            </div>
          </div>
        </div>
        <div className="info__socials-outer">
          <div className="info__socials">
            <a
              href=" https://twitter.com/Spredict_io"
              target="_blank"
              className="info__social"
            >
              {twitterIcon}
            </a>
            <a
              href="https://spredict.io"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/language.png"}
                alt=""
              />
            </a>

            <a
              href="https://t.me/SpredictOfficialPortal"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/telegram.png"}
                alt=""
              />
            </a>
          </div>
          <div className="info__socials">
            <a
              href="https://discord.gg/spredict"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/discord.png"}
                alt=""
              />
            </a>
            <a
              href="https://medium.com/@Spredict"
              target="_blank"
              className="info__social"
            >
              <img
                src={process.env.PUBLIC_URL + "/images/icons/medium.png"}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
