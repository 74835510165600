import React from "react";
import { closeIcon } from "../../Base/SVG";
import Slider from "react-slick";

export default function Started({ setModal }) {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: false,
    fade: true,
  };
  return (
    <>
      <div className="modall__bg"></div>
      <div className="modall__element"></div>
      <div className="modall__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modall__title">Getting started</div>
      <div className="modall__subtitle">Instructions</div>
      <div className="modall__slider">
        <Slider {...settings}>
          <div className="modall__slider-item">
            <h6>Currently, you must create a wallet on Metamask to play.</h6>
            <p>
              If using a desktop computer, we suggest using Brave or Google
              Chrome browser. For mobile, players must use the Metamask app
              browser directly.
            </p>
            <div className="modall__slider-item-meta">
              <img
                src={process.env.PUBLIC_URL + "/images/started/meta.png"}
                alt=""
              />
            </div>
          </div>
          <div className="modall__slider-item">
            <h6>Step 1</h6>
            <p>
              Create your account following the instructions in the Metamask app
              for mobile or the extension for desktop browsers.
            </p>
            <div className="modall__slider-item-image">
              <img
                src={process.env.PUBLIC_URL + "/images/started/account.png"}
                alt=""
              />
            </div>
          </div>
          <div className="modall__slider-item">
            <h6>Step 2</h6>
            <p>
              After creating or selecting the wallet you would like to use,
              please sign the SPREDICT network message that appears. <br />
              You can now enjoy our Free2Play version.
            </p>
            <div className="modall__slider-item-image">
              <img
                src={process.env.PUBLIC_URL + "/images/started/network.png"}
                alt=""
              />
            </div>
          </div>
          <div className="modall__slider-item">
            <h6>Step 3</h6>
            <p>
              To enjoy the full experience, including competing for points and
              prizes on a monthly and seasonal basis, you will need to purchase
              a subscription with Ethereum (ETH) in your Metamask wallet.
            </p>
            <div className="modall__slider-item-eth">
              <img
                src={process.env.PUBLIC_URL + "/images/started/eth.png"}
                alt=""
              />
            </div>
          </div>
          <div className="modall__slider-item">
            <h6>Congrats !</h6>
            <p>
              Once you have ETH in your wallet, click on the "Get Your Pass"
              button <span className="mob">.</span>{" "}
              <span>on the left side of our app.</span> When you have proceeded
              with the subscription transaction you can begin accumulating
              points in the leaderboard (individually and with a team) and earn
              prizes!
              <br />
              <br />
              Get your engine started!
            </p>
            <div className="modall__slider-item-league">
              <img
                src={process.env.PUBLIC_URL + "/images/started/league.png"}
                alt=""
              />
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}
