import React, { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import dualAbi from "../Abi/dual.json";
import { RaceModul } from "../Base/RaceModul"
import { FormulaModul } from "../Index/components/DriverList"
import * as chains from "../Base/Chains"
import axios from 'axios'

export const currentRaceId = 16;
const currentSessionId = currentRaceId + 1;
const f1Length = 10;
// const start = 1709388000 // Sat Mar 02 2024 14:00:00 GMT+0000
const ethereumEvents = ["accountsChanged", "connect", "disconnect"]

// const sleep = async ms => {
//     return new Promise(resolve => setTimeout(() => resolve(true), ms));
// }



const allowedNetworks = [
    // "0xa4b1", // arbitrum
    "0x153e6923d4b", // dao testnet
]

const modalNetwork = [
    "0x1", // ethereum
    "0xa4b1", // arbitrum
    "0x2105", // base
    "0x89" // polygon
]

// const modalPaymentList = ["connect","pay","congrats"]
const modalPaymentList = []

const txOptions = { gasLimit: "1000000" };


const shortedAddress = address => {
    const len = address.length
    const start = address.slice(0, 4)
    const end = address.slice(len - 4, len)
    return start + "..." + end
}
export const FirstContext = createContext()

const FirstContextProvider = (props) => {
    const [signer, setSigner] = useState(null);
    const [account, setAccount] = useState(null);
    const [allowedNetwork, setAllowedNetwork] = useState(false);
    const [allowedModalNetwork, setAllowedModalNetwork] = useState(false);
    const [dual, setDual] = useState(null);
    const [sessionExist,  setSessionExist ] = useState(false);
    const [alreadyPlayed,  setAlreadyPlayed ] = useState(true);
    const [rigthSessionLength,  setRigthSessionLength ] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
    const [openErrorMessage, setOpenErrorMessage] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [activeButton, setActiveButton] = useState(false);
    const [fundRequest, setFundRequest] = useState(false);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);
    const [sessionsLength, setSessionsLength] = useState(0);
    const [modal, setModal] = useState(null);
    const [chainID, setChainID] = useState(null);

    const [form, setForm] = useState({
        R001: [],
        R002: [],
        R003: [],
        R004: [],
        R005: [],
        R006: [],
        R007: [],
        R008: [],
        R009: [],
        R010: [],
        R011: [],
        R012: [],
        R013: [],
        R014: [],
        R015: [],
        R016: [],
        R017: [],
        R018: [],
        R019: [],
        R020: [],
        R021: [],
        R022: [],
        R023: [],
        R024: [],
    });

    const [historical, setHistorical] = useState({
        R001: [],
        R002: [],
        R003: [],
        R004: [],
        R005: [],
        R006: [],
        R007: [],
        R008: [],
        R009: [],
        R010: [],
        R011: [],
        R012: [],
        R013: [],
        R014: [],
        R015: [],
        R016: [],
        R017: [],
        R018: [],
        R019: [],
        R020: [],
        R021: [],
        R022: [],
        R023: [],
        R024: [],
    });

    const [userChoices, setUserChoices] = useState({
        R001: [],
        R002: [],
        R003: [],
        R004: [],
        R005: [],
        R006: [],
        R007: [],
        R008: [],
        R009: [],
        R010: [],
        R011: [],
        R012: [],
        R013: [],
        R014: [],
        R015: [],
        R016: [],
        R017: [],
        R018: [],
        R019: [],
        R020: [],
        R021: [],
        R022: [],
        R023: [],
        R024: [],
    });


    const applyChoices = async() => {
        try {
            
            if(alreadyPlayed) {
                const choices = await dual.getPlayerChoices(account, currentSessionId)
                if(choices.length === f1Length) {
                    const obj = []
                    for(let i = 0; i < choices.length; i++) {
                        const choice = choices[i];
                        const f1Module = FormulaModul[i];
                        
                        const result = {
                            "id": f1Module.id,
                            "team": f1Module.teamCode,
                            "driverChosen": choice ? f1Module.driver1 : f1Module.driver2,
                            "true_false": choice
                        };

                        obj.push(result);
                    }

                    const round = RaceModul[currentRaceId].round;

                    setForm({
                        ...form,
                        [round]: obj
                    })
                }
            }
        } catch(e) {
            console.log(e.message)
        }
    }

    
    
    const updateForm = (data) => {
        setForm((form) => ({ ...form, ...data }));
    };
    
    const updateSigner = async() => {
        if (window.ethereum) {
            try {
                const provider = new ethers.BrowserProvider(window.ethereum, "any")
                const signer = await provider.getSigner();
                setSigner(signer);
    
                ethereumEvents.map(async (element) => {
                    await window.ethereum.on(element, () => {
                        window.location.reload()
                    });
                });
            } catch(e) {
                console.log(e.message)
            }
        } else {
            setSigner(null);
        }
    }

    const connectWallet = async() => {
        try {
            addNetwork(chains.DAOTestnet);
        } catch(e) {
            console.log(e.message)    
        }
    }

    const connectWalletMainnet = async() => {
        try {
            if(modalPaymentList.includes(modal)){
                if(!signer) {
                    addNetwork(chains.EthereumMainnet);
                } else {
                    switchNetwork("0x1")
                }

                setModal("pay")
            }
        } catch(e) {
            console.log(e.message);
        }
    
    }

    useEffect(()=>{
        if(signer) {
            setAccount(signer.address);
        } else {
            setAccount(null);
        }
    }, [signer])

    useEffect(()=>{
        if (window.ethereum) {
            if(!signer) {
                connectWallet();
            } else {
                updateAllowedNetworks()
            }
        } 
    }, [signer])


    useEffect(()=>{
        if (window.ethereum && !modalPaymentList.includes(modal)) {
            if(!signer) {
                connectWallet();
            } else {
                switchNetwork(allowedNetworks[0])
            }
        } 
    }, [modal])

  


    useEffect(()=>{
        if(
            signer && 
            account && 
            allowedNetworks && 
            process.env.REACT_APP_DUAL_MODE
        ) {
            try {
                setDual(new ethers.Contract(process.env.REACT_APP_DUAL_MODE, dualAbi, signer));
            } catch(e){
                console.log(e.message)
            }
        }

    }, [signer, account, allowedNetwork])



    useEffect(()=>{
        if(dual) {
            try {
                checkSession().then(res => {
                    setSessionExist(res)
                });
            } catch(e){
                console.log(e.message)
            }
        }

    }, [dual])

    useEffect(()=>{
        if(dual) {
            try {
                checkSessionsLength().then(length => {
                    if(length > 0) {
                        setSessionsLength(length);
                    }
                })

            } catch(e){
                console.log(e.message)
            }
        }

    }, [dual])

    useEffect(()=>{
        if(sessionsLength > 0) {
            try {
                getUserChoices(sessionsLength);
            } catch(e){
                console.log(e.message)
            }
        }

    }, [sessionsLength])


    useEffect(()=>{
        if(sessionsLength > 0) {
            try {
                getHistoricalResults(sessionsLength);
            } catch(e){
                console.log(e.message)
            }
        }

    }, [sessionsLength])


    useEffect(()=>{
        if(sessionExist) {
            try {
                checkRigthSessionLength().then(res => {
                    setRigthSessionLength(res === f1Length)
                });

                checkAlreadyPlayed().then(res => {
                    setAlreadyPlayed(res)
                    if(res === true) {
                        applyChoices()
                    }
                });

                checkLock().then(res => {
                    setStart(res)
                });

                checkEnd().then(res => {
                    setEnd(res)
                });


            } catch(e){
                console.log(e.message)
            }
        }
    }, [sessionExist])

   const initializeProvider = async() => {
        if (window.ethereum) {
            try {
                addNetwork(chains.DAOTestnet);
            } catch (e) {

                console.log(e.reason);
            }
        }
    }

    const checkSession = async() => {
        try {
            return await dual.sessionExist(currentSessionId);
        } catch(e) {
            return false
        }
    }

    const checkAlreadyPlayed = async() => {
        try {
            return await dual.getPlayerPlayed(signer.address, currentSessionId);
        } catch(e) {
            return true
        }
    }

    const checkRigthSessionLength = async() => {
        try {
            return parseInt(await dual.getSessionLength(currentSessionId));
        } catch(e) {
            return 0
        }
    }

    const checkLock = async() => {
        try {
            return parseInt(await dual.getSessionLock(currentSessionId));
        } catch(e) {
            return 0
        }
    }

    const checkEnd = async() => {
        try {
            return parseInt(await dual.getSessionEnd(currentSessionId));
        } catch(e) {
            return 0
        }
    }

    useEffect(()=>{
        if(
            signer,
            account,
            allowedNetwork,
            dual,
            sessionExist,
            !alreadyPlayed,
            rigthSessionLength
        ) {
            setActiveButton(true)
        } else {
            setActiveButton(false)

        }
    }, [
        signer,
        account,
        allowedNetwork,
        dual,
        sessionExist,
        alreadyPlayed,
        rigthSessionLength
    ])

    const bet = async () => {
        const now = parseInt(new Date() / 1000)
        if(
            signer &&
            account &&
            allowedNetwork &&
            dual &&
            sessionExist &&
            !alreadyPlayed &&
            rigthSessionLength &&
            activeButton &&
            now < start
        ) {
            try {
                const round = RaceModul[currentRaceId].round;
                const choices = form[round].map(el => el["true_false"]);              
                
                if(choices.length === f1Length) {
                    const tx = await dual.bet(
                        currentSessionId,
                        choices,
                        [],
                        txOptions
                    )

                    setOpenBackdrop(true)

                    const receipt = await tx.wait();
                    setOpenBackdrop(false)

                    if(parseInt(receipt.status) === 1) {
                        setOpenSuccessMessage(true);
                        setAlreadyPlayed(true);
                        setActiveButton(false)
                    } else {
                        setOpenErrorMessage(false)
                    }
                }
            } catch(e) {
                setOpenBackdrop(false)
                setOpenErrorMessage(false)
            }

        }
    }

    const checkSessionsLength = async() => {
        if(dual) {
            try {
                return parseInt(await dual.getSessionsLength());
            } catch(e) {
                return 0;
            }
        }
    }

    const getUserChoices = async(length) => {
        if(dual && account) {
            const arr = []
            try {
                const obj = {...userChoices};
                for(let i = 0; i < length; i++) {
                    const played = await dual.getPlayerPlayed(account, i);
                    if(played) {
                        const raceChoices = (await dual.getPlayerChoices(
                            account,
                            i
                        )).map(el=>el);

                        obj[RaceModul[i].round] = raceChoices;
                    }
                };

                setUserChoices(obj);
            } catch(e) {
                return arr;
            }
        }
    }

    const getHistoricalResults = async(length) => {
        if(dual) {
            const arr = []
            try {
                const obj = {...historical}
                for(let i = 0; i < length; i++) {
                    const counted = await dual.getSessionCounted(i);
                    if(counted) {
                        const raceResults = (await dual.getSessionResults(i)).map(el => el);
                        obj[RaceModul[i].round] = raceResults;
                    }
                };

                setHistorical(obj);

            } catch(e) {
                return arr;
            }
        }
    }



    useEffect(() => {
        try {
            if (allowedNetwork  && account && signer && !fundRequest) {
                setFundRequest(true)
                signer.provider.getBalance(account).then(balance => {
                    if (balance.toString() === "0") {
                        signer.signMessage(process.env.REACT_APP_MESSAGE, account).then(signature => {

                            const baseUrl = "https://balance-api.defiallodds.io/"
                            const url = baseUrl + "fund"

                            const postObj = {
                                method: 'post',
                                url,
                                headers: {},
                                data: {
                                    signature,
                                    address: account
                                }
                            }
                            setOpenBackdrop(true)
                            axios(postObj).then(res => {
                                if (res.data.includes("was funded 1 SPI:")) {
                                    // address funded
                                    // alert(res.data)
                                    setOpenBackdrop(false)
                                    setOpenSuccessMessage(true)
                                }
                            })

                        })
                    }
                })
            }
        } catch (e) {
            setOpenBackdrop(false)
            setFundRequest(false)
        }
    }, [allowedNetwork, account])

    const addNetwork = async(chainData)=> {
        if(window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [chainData]
                });

                updateSigner();
                updateAllowedNetworks();
            } catch(e) {

                console.log(e.message);
            }
        } 
    }

    const switchNetwork = async(chainId)=> {
        if(window.ethereum) {
            try {
                await window.ethereum.request({
                    "method": "wallet_switchEthereumChain",
                    "params": [
                      {
                        "chainId": chainId
                      }
                    ]
                  });

                updateSigner();
                updateAllowedNetworks();
            } catch(e) {
                console.log(e.message);
            }
        } 
    }

    const updateAllowedNetworks = async ()=> {
        try {
            window.ethereum.request({method: 'eth_chainId'}).then(chainId => {
                setChainID(chainId)
                updateAllowedNetwork(chainId);
            })

        } catch(e) {
            console.log(e.message)
        }
    }


    const updateAllowedNetwork = chainId => {
        if(allowedNetworks.includes(chainId)) {
            setAllowedModalNetwork(false)
            setAllowedNetwork(true);
        } else if(modalNetwork.includes(chainId)) {
            setAllowedNetwork(false)
            setAllowedModalNetwork(true)
        } else {
            setAllowedNetwork(false)
            setAllowedModalNetwork(false)
        }
    }

   


    return (
        <FirstContext.Provider value={{
            initializeProvider,
            account, 
            shortedAddress,
            openMessage,
            setOpenMessage,
            openSuccessMessage,
            setOpenSuccessMessage,
            openBackdrop,
            setOpenBackdrop,
            openErrorMessage,
            setOpenErrorMessage,
            activeButton,
            form,
            updateForm,
            alreadyPlayed,
            start,
            end,
            bet,
            historical,
            userChoices,
            sessionsLength,
            connectWalletMainnet,
            modal,
            setModal,
            modalNetwork,
            modalPaymentList,
            signer,
            allowedModalNetwork,
            switchNetwork,
            chainID,
            ethers
        }}>
            {props.children}
        </FirstContext.Provider>
    )
}

export default FirstContextProvider