import  { Fragment } from 'react'
import { Routes, Route } from "react-router-dom";
import Index from "./Index/Index";
import FirstContextProvider from './Context/FirstContext.jsx'

function App() {
  return (
    <Fragment>
      <FirstContextProvider>
        <Routes>
          <Route path="*" element={<Index />} />
        </Routes>
      </FirstContextProvider>
    </Fragment>
  );
}

export default App;
