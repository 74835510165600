export const RaceModul = [
  {
    id: "1",
    round: "R001",
    info_circuit: "BAHRAIN INTERNATIONAL CIRCUIT",
    where: "BAHRAIN",
    when: "29 FEB - 02 MAR",
    qualifs: "01/03 4PM UTC",
    gp: "02/03 3PM UTC",
    first_gp: "2004",
    number_of_laps: "57",
    circuit_length: "5.412 km",
    race_distance: "308.238 km",
    lap_record: "1:31.447 (2005)",
    flag: "/images/flags/1.png",
    map: "/images/maps/1.png",
  },
  {
    id: "2",
    round: "R002",
    info_circuit: "JEDDAH CORNICHE CIRCUIT",
    where: "SAUDI ARABIA",
    when: "07 MAR - 09 MAR",
    qualifs: "08/03 5PM UTC",
    gp: "09/03 5PM UTC",
    first_gp: "2021",
    number_of_laps: "50",
    circuit_length: "6.174 km",
    race_distance: "308.45 km",
    lap_record: "1:30.734 (2021 LH44)",
    flag: "/images/flags/2.png",
    map: "/images/maps/2.png",
  },
  {
    id: "3",
    round: "R003",
    info_circuit: "MELBOURNE GRAND PRIX CIRCUIT",
    where: "AUSTRALIA",
    when: "22 MAR - 24 MAR",
    qualifs: "23/03 4AM UTC",
    gp: "24/03 4AM UTC",
    first_gp: "1996",
    number_of_laps: "58",
    circuit_length: "5.278 km",
    race_distance: "306.124 km",
    lap_record: "1:20.235 (2023)",
    flag: "/images/flags/3.png",
    map: "/images/maps/3.png",
  },
  {
    id: "4",
    round: "R004",
    info_circuit: "SUZUKA INTERNATIONAL",
    where: "JAPAN",
    when: "05 APR - 07 APR",
    qualifs: "06/04 6AM UTC",
    gp: "07/04 5AM UTC",
    first_gp: "1987",
    number_of_laps: "53",
    circuit_length: "5.807 km",
    race_distance: "307.471 km",
    lap_record: "1:30.983 (2019 LH44)",
    flag: "/images/flags/4.png",
    map: "/images/maps/4.png",
  },
  {
    id: "5",
    round: "R005",
    info_circuit: "SHANGAI INTERNATIONAL CIRCUIT",
    where: "CHINA",
    when: "TBC - 21 APR",
    qualifs: "TBC",
    gp: "21/04 7AM UTC",
    first_gp: "2004",
    number_of_laps: "56",
    circuit_length: "5.451 km",
    race_distance: "305.066 km",
    lap_record: "1:32.238 (2004)",
    flag: "/images/flags/5.png",
    map: "/images/maps/5.png",
  },
  {
    id: "6",
    round: "R006",
    info_circuit: "MIAMI INTERNATIONAL AUTODROME",
    where: "USA",
    when: "TBC - 05 MAY",
    qualifs: "TBC",
    gp: "05/05 8PM UTC",
    first_gp: "2022",
    number_of_laps: "57",
    circuit_length: "5.412 km",
    race_distance: "308.326 km",
    lap_record: "1:29.708 (2023)",
    flag: "/images/flags/6.png",
    map: "/images/maps/6.png",
  },
  {
    id: "7",
    round: "R007",
    info_circuit: "AUTODROMO ENZO E DINO FERRARI",
    where: "ITALY",
    when: "17 MAY - 19 MAY",
    qualifs: "18/05 2PM UTC",
    gp: "19/05 1PM UTC",
    first_gp: "1980",
    number_of_laps: "63",
    circuit_length: "4.909 km",
    race_distance: "309.049 km",
    lap_record: "1:15.484 (2020 LH44)",
    flag: "/images/flags/7.png",
    map: "/images/maps/7.png",
  },
  {
    id: "8",
    round: "R008",
    info_circuit: "CIRCUIT DE MONACO",
    where: "MONACO",
    when: "24 MAY - 26 MAY",
    qualifs: "25/05 2PM UTC",
    gp: "26/05 1PM UTC",
    first_gp: "1950",
    number_of_laps: "78",
    circuit_length: "3.337 km",
    race_distance: "260.286 km",
    lap_record: "1:12.909 (2021 LH44)",
    flag: "/images/flags/8.png",
    map: "/images/maps/8.png",
  },
  {
    id: "9",
    round: "R009",
    info_circuit: "Circuit Gilles-Villeneuve",
    where: "CANADA",
    when: "08 JUN - 09 JUN",
    qualifs: "08/06 8PM UTC",
    gp: "09/06 6PM UTC",
    first_gp: "1978",
    number_of_laps: "70",
    circuit_length: "4.361 km",
    race_distance: "305.27 km",
    lap_record: "1:13.078 (2019)",
    flag: "/images/flags/9.png",
    map: "/images/maps/9.png",
  },
  {
    id: "10",
    round: "R010",
    info_circuit: "CIRCUIT DE BARCELONA-CATALUNYA",
    where: "SPAIN",
    when: "21 JUN - 23 JUN",
    qualifs: "22/06 2PM UTC",
    gp: "23/06 1PM UTC",
    first_gp: "1991",
    number_of_laps: "66",
    circuit_length: "4.657 km",
    race_distance: "307.236 km",
    lap_record: "1:16.330 (2023)",
    flag: "/images/flags/10.png",
    map: "/images/maps/10.png",
  },
  {
    id: "11",
    round: "R011",
    info_circuit: "RED BULL RING",
    where: "AUSTRIA",
    when: "TBC - 30 JUN",
    qualifs: "TBC",
    gp: "30/06 1PM UTC",
    first_gp: "1970",
    number_of_laps: "71",
    circuit_length: "4.318 km",
    race_distance: "306.452 km",
    lap_record: "1:05.619 (2020)",
    flag: "/images/flags/11.png",
    map: "/images/maps/11.png",
  },
  {
    id: "12",
    round: "R012",
    info_circuit: "SILVERSTONE CIRCUIT",
    where: "UNITED KINGDOM",
    when: "05 JUL - 07 JUL",
    qualifs: "06/07 2PM UTC",
    gp: "07/07 2PM UTC",
    first_gp: "1950",
    number_of_laps: "52",
    circuit_length: "5.891 km",
    race_distance: "306.198 km",
    lap_record: "1:27.097 (2020)",
    flag: "/images/flags/12.png",
    map: "/images/maps/12.png",
  },
  {
    id: "13",
    round: "R013",
    info_circuit: "HUNGARORING",
    where: "HUNGARY",
    when: "19 JUL - 21 JUL",
    qualifs: "20/07 2PM UTC",
    gp: "21/07 1PM UTC",
    first_gp: "1986",
    number_of_laps: "70",
    circuit_length: "306.63 km",
    race_distance: "306.198 km",
    lap_record: "1:16.627 (2020 LH44)",
    flag: "/images/flags/13.png",
    map: "/images/maps/13.png",
  },
  {
    id: "14",
    round: "R014",
    info_circuit: "CIRCUIT DE SPA-FRANCORCHAMPS",
    where: "BELGIUM",
    when: "26 JUL - 28 JUL",
    qualifs: "27/07 2PM UTC",
    gp: "28/07 1PM UTC",
    first_gp: "1950",
    number_of_laps: "44",
    circuit_length: "7.004 km",
    race_distance: "308.052 km",
    lap_record: "1:46.286 (2018)",
    flag: "/images/flags/14.png",
    map: "/images/maps/14.png",
  },
  {
    id: "15",
    round: "R015",
    info_circuit: "CIRCUIT ZANDVOORT",
    where: "NETHERLANDS",
    when: "23 AUG - 25 AUG",
    qualifs: "24/08 1PM UTC",
    gp: "25/08 1PM UTC",
    first_gp: "1952",
    number_of_laps: "72",
    circuit_length: "4.259 km",
    race_distance: "306.587 km",
    lap_record: "1:11.097 (2021 LH44)",
    flag: "/images/flags/15.png",
    map: "/images/maps/15.png",
  },
  {
    id: "16",
    round: "R016",
    info_circuit: "AUTODROMO NAZIONALE MONZA",
    where: "ITALY",
    when: "30 AUG - 01 SEP",
    qualifs: "31/08 2PM UTC",
    gp: "01/09 1PM UTC",
    first_gp: "1950",
    number_of_laps: "53",
    circuit_length: "5.793 km",
    race_distance: "306.72 km",
    lap_record: "1:21.046 (2004)",
    flag: "/images/flags/16.png",
    map: "/images/maps/16.png",
  },
  {
    id: "17",
    round: "R017",
    info_circuit: "BAKU CITY CIRCUIT",
    where: "AZERBAIJAN",
    when: "13 SEP - 15 SEP",
    qualifs: "14/09 12PM UTC",
    gp: "15/09 11AM UTC",
    first_gp: "2016",
    number_of_laps: "51",
    circuit_length: "6.003 km ",
    race_distance: "306.049 km",
    lap_record: "1:43.009 (2019)",
    flag: "/images/flags/17.png",
    map: "/images/maps/17.png",
  },
  {
    id: "18",
    round: "R018",
    info_circuit: "MARINA BAY STREET CIRCUIT",
    where: "SINGAPORE",
    when: "20 SEP - 22 SEP",
    qualifs: "21/09 1PM UTC",
    gp: "22/09 12PM UTC",
    first_gp: "2008",
    number_of_laps: "62",
    circuit_length: "4.94 km",
    race_distance: "306.143 km",
    lap_record: "1:35.867 (2023 LH44)",
    flag: "/images/flags/18.png",
    map: "/images/maps/18.png",
  },
  {
    id: "19",
    round: "R019",
    info_circuit: "CIRCUIT OF THE AMERICAS",
    where: "USA",
    when: "TBC - 20 OCT",
    qualifs: "TBC",
    gp: "20/10 7PM UTC",
    first_gp: "2012",
    number_of_laps: "56",
    circuit_length: "5.513 km",
    race_distance: "308.405 km",
    lap_record: "1:36.169 (2019)",
    flag: "/images/flags/19.png",
    map: "/images/maps/19.png",
  },
  {
    id: "20",
    round: "R020",
    info_circuit: "AUTÓDROMO HERMANOS RODRÍGUEZ",
    where: "MEXICO",
    when: "25 OCT - 27 OCT",
    qualifs: "26/10 9PM UTC",
    gp: "27/10 8PM UTC",
    first_gp: "1963",
    number_of_laps: "71",
    circuit_length: "4.304 km",
    race_distance: "305.354 km",
    lap_record: "1:17.774 (2021)",
    flag: "/images/flags/20.png",
    map: "/images/maps/20.png",
  },
  {
    id: "21",
    round: "R021",
    info_circuit: "AUTÓDROMO JOSÉ CARLOS PACE",
    where: "BRAZIL",
    when: "TBC - 03 NOV",
    qualifs: "TBC",
    gp: "03/11 5PM UTC",
    first_gp: "1973",
    number_of_laps: "71",
    circuit_length: "4.309 km",
    race_distance: "305.879 km",
    lap_record: "1:10.540 (2018)",
    flag: "/images/flags/21.png",
    map: "/images/maps/21.png",
  },
  {
    id: "22",
    round: "R022",
    info_circuit: "LAS VEGAS STRIP CIRCUIT",
    where: "USA",
    when: "TBC - 24 NOV",
    qualifs: "TBC",
    gp: "24/11 6AM UTC",
    first_gp: "2023",
    number_of_laps: "50",
    circuit_length: "6.201 km",
    race_distance: "309.958 km",
    lap_record: "1:35.490 (2023)",
    flag: "/images/flags/22.png",
    map: "/images/maps/22.png",
  },
  {
    id: "23",
    round: "R023",
    info_circuit: "LUSAIL INTERNATIONAL CIRCUIT",
    where: "QATAR",
    when: "TBC - 01 DEC",
    qualifs: "TBC",
    gp: "01/12 5PM UTC",
    first_gp: "2021",
    number_of_laps: "57",
    circuit_length: "5.419 km",
    race_distance: "308.611 km",
    lap_record: "1:24.319 (2023)",
    flag: "/images/flags/23.png",
    map: "/images/maps/23.png",
  },
  {
    id: "24",
    round: "R024",
    info_circuit: "YAS MARINA CIRCUIT",
    where: "UAE",
    when: "06 DEC - 08 DEC",
    qualifs: "07/12 2PM UTC",
    gp: "08/12 1PM UTC",
    first_gp: "2009",
    number_of_laps: "58",
    circuit_length: "5.281 km",
    race_distance: "306.183 km",
    lap_record: "1:26.103 (2021)",
    flag: "/images/flags/24.png",
    map: "/images/maps/24.png",
  },
];
