import React, { useState, useContext/* , useEffect */ } from "react";
import { cancelIcon, expandIcon, tickIcon } from "../../../Base/SVG";
import { AnimatePresence, motion } from "framer-motion";
import { RaceModul } from "../../../Base/RaceModul";
import { FormulaModul } from "../DriverList";
import { FirstContext } from "../../../Context/FirstContext";

const base = [
  {
    id: "1",
    team: "A",
    true_false: true,
  },
  {
    id: "2",
    team: "B",
    true_false: true,
  },
  {
    id: "3",
    team: "C",
    true_false: true,
  },
  {
    id: "4",
    team: "D",
    true_false: true,
  },
  {
    id: "5",
    team: "E",
    true_false: true,
  },
  {
    id: "6",
    team: "F",
    true_false: true,
  },
  {
    id: "7",
    team: "G",
    true_false: true,
  },
  {
    id: "8",
    team: "H",
    true_false: true,
  },
  {
    id: "9",
    team: "I",
    true_false: true,
  },
  {
    id: "10",
    team: "J",
    true_false: true,
  },
]

export default function Historical() {
  const [resultsModul/* , setResultsModul */] = useState({
    R001: base,
    R002: base,
    R003: base,
    R004: base,
    R005: base,
    R006: base,
    R007: base,
    R008: base,
    R009: base,
    R010: base,
    R011: base,
    R012: base,
    R013: base,
    R014: base,
    R015: base,
    R016: base,
    R017: base,
    R018: base,
    R019: base,
    R020: base,
    R021: base,
    R022: base,
    R023: base,
    R024: base
  });

  const [active, setActive] = useState(null);
  const { 
    historical,
    userChoices,
    sessionsLength
   } = useContext(FirstContext);

  return (
    <div className="content__history">
      <div className="content__history-items">
        {RaceModul?.map((item, index) => {
          return (
            <ResultItem
              itemData={item}
              key={index}
              resultsModul={resultsModul}
              setActive={setActive}
              active={active}
              historical={historical}
              userChoices={userChoices}
              sessionsLength={sessionsLength}
            />
          );
        })}
      </div>
    </div>
  );
}
const ResultItem = ({ 
  itemData, 
  resultsModul, 
  setActive, 
  active, 
  historical,
  userChoices 
}) => {
  return (
    <div className="content__history-item">
      <div
        className={
          "content__history-item-title " +
          (active === itemData?.id ? "active" : "")
        }
        onClick={() => {
          if (active === itemData?.id) {
            setActive(null);
          } else {
            setActive(itemData?.id);
          }
        }}
      >
        <h6>{itemData?.info_circuit}</h6>
        {expandIcon}
      </div>
      <AnimatePresence>
        {active === itemData?.id && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, y: 10 }}
            className={`content__history-item-content  ${
              active ? "active" : ""
            } `}
          >
            {FormulaModul?.map((item, index) => {
              console.log(userChoices?.[itemData?.round]);
              // const resultInfo = resultsModul?.[itemData?.round]?.filter(
              //   (filterItem) => filterItem?.team === item?.teamCode
              // )[0];
              return (
                <div className="content__history-item-row" key={index}>
                  <h5>{item?.teamNameSm}</h5>
                  <div className="content__history-item-row-body">
                    <div
                      className={
                        "content__history-item-row-body-item " + 
                        (
                          historical?.[itemData?.round]?.[index] === true 
                          ?(
                            userChoices?.[itemData?.round]?.length === FormulaModul?.length
                            && userChoices?.[itemData?.round]?.[index] === historical?.[itemData?.round]?.[index]?
                            "winner"
                            :"selected"
                          )
                          : ""
                        )
                      }
                    >
                      {item?.driver1?.name}
                    </div>
                    <div className="content__history-item-row-body-image">
                      <img
                        src={process.env.PUBLIC_URL + "/images/vs.png"}
                        alt=""
                      />
                    </div>
                    <div
                      className={
                        "content__history-item-row-body-item " +
                        (
                          historical?.[itemData?.round]?.[index] === false 
                          ?(
                            userChoices?.[itemData?.round]?.length === FormulaModul?.length
                            && userChoices?.[itemData?.round]?.[index] === historical?.[itemData?.round]?.[index]?
                            "winner"
                            :"selected"
                          )
                          : ""
                        )
                      }
                    >
                      {item?.driver2?.name}
                    </div>
                  </div>
                  <div
                    className={
                      "content__history-item-icon " + (
                        historical?.[itemData?.round]?.[index]  === true || historical?.[itemData?.round]?.[index]  === false
                        ? (
                          userChoices?.[itemData?.round]?.length === FormulaModul?.length
                          && userChoices?.[itemData?.round]?.[index] === historical?.[itemData?.round]?.[index]
                          ? "winner"
                          : "selected"
                        )
                        : ""
                      )
                    }
                  >
                    {
                      userChoices?.[itemData?.round]?.length === FormulaModul?.length
                      && userChoices?.[itemData?.round]?.[index] === historical?.[itemData?.round]?.[index]
                        ? tickIcon
                        : cancelIcon
                    }
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
