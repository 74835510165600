import React, { useContext } from "react";
import { FormulaModul } from "../DriverList";
import { RaceModul } from "../../../Base/RaceModul";
import Countdown from "react-countdown";
import { FirstContext } from "../../../Context/FirstContext";
import { engineIcon } from "../../../Base/SVG";
export default function NextGP({ race, form, setModal, updateForm }) {
  const {
    openMessage,
    setOpenMessage,
    openSuccessMessage,
    setOpenSuccessMessage,
    openBackdrop,
    setOpenBackdrop,
    openErrorMessage,
    setOpenErrorMessage,
    activeButton,
    alreadyPlayed,
    start,
    end,
    bet,
  } = useContext(FirstContext);
  return (
    <>
      <div className="content__circuit">
        <div className="content__circuit-info">
          <div className="content__circuit-info-round">
            <p className="sm">Round</p>
            <h6 className="xsm">
              {RaceModul?.map((item) => item.id).indexOf(race.id) + 1}/24
            </h6>
            <img src={process.env.PUBLIC_URL + race?.flag} alt="flag" />
          </div>
          <div className="content__circuit-info-text">
            <h1>{race?.where}</h1>
            <p className="sm">FORMULA 1 {race.info_circuit} GRAND PRIX 2024</p>
          </div>
        </div>
        <div className="content__circuit-date">
          <h5>{race?.when}</h5>
          <h5 className="light">2024</h5>
        </div>
      </div>
      <div className="content__info">
        {form?.[race?.round]?.length === 10 ? (
          <h6>Relax ! your bet is taken into account</h6>
        ) : (
          <p>
            ONLY{" "}
            <span>
              {
              start > 0
              ? <Countdown
                date={start * 1000}
              />
              : <Countdown
              date={new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000)}
            />
              }
             
            </span>{" "}
            LEFT TO PLAY
          </p>
        )}
      </div>
      <div className="content__inst">
        <p className="sm">Select one driver per team</p>
        <button
          type="button"
          className="content__inst-btn"
          onClick={() => setModal("inst")}
        >
          See full instructions
        </button>
      </div>
      <div className="content__cards">
        {FormulaModul?.map((item, index) => {
          return (
            <CardItem
              race={race}
              key={index}
              itemData={item}
              form={form}
              updateForm={updateForm}
              lock={alreadyPlayed}
              start={start}
            />
          );
        })}
      </div>
    </>
  );
}
const CardItem = ({ itemData, form, updateForm, race, lock, start }) => {
  const pickHandler = (driverChosen, true_false) => {
    let arr = [...form?.[race?.round]];
    const index = arr.map((item) => item.id).indexOf(itemData.id);
    if (index === -1) {
      updateForm({
        [race?.round]: [
          ...form?.[race?.round],
          {
            id: itemData.id,
            team: itemData.teamCode,
            driverChosen: driverChosen,
            true_false: true_false,
          },
        ],
      });
    } else {
      if (form?.[race?.round][index].driverChosen.code === driverChosen.code) {
        updateForm({
          [race?.round]: [
            ...form?.[race?.round]?.filter((item) => item.id !== itemData.id),
          ],
        });
      } else {
        arr[index] = {
          ...arr[index],
          driverChosen: driverChosen,
          true_false: true_false,
        };
        updateForm({ [race?.round]: arr });
      }
    }
  };
  return (
    <div
      className={
        "card " +
        (form?.[race?.round]?.filter((item) => item.id === itemData.id).length >
        0
          ? "active"
          : "")
      }
    >
      <div className="card__inner">
        <div className="card__info">
          <h2>{itemData?.teamNameSm}</h2>
          <div className="card__info-items">
            <div className="card__info-item">
              <img src={process.env.PUBLIC_URL + itemData?.flag} alt="" />
              <p className="sm">{itemData?.teamName}</p>
            </div>
            <div className="card__info-item">
              {engineIcon}
              <p className="sm">{itemData?.engine}</p>
            </div>
          </div>
        </div>
        <div className="card__row">
          <div
            className={
              "card__item " +
              (form?.[race?.round]?.filter((item) => item.id === itemData.id)
                .length > 0
                ? form?.[race?.round]?.filter(
                    (item) => item.id === itemData.id
                  )[0].driverChosen.code === itemData.driver1.code
                  ? "active"
                  : ""
                : "")
            }
            onClick={() =>
              !lock && parseInt(new Date() / 1000) < start
                ? pickHandler(itemData.driver1, true)
                : null
            }
          >
            <div className="card__item-inner">
              <div className="card__item-row">
                <div className="card__item-info">
                  <div className="card__item-info-image">
                    <img
                      src={process.env.PUBLIC_URL + "/images/helmet.png"}
                      alt=""
                    />
                  </div>
                  <h3 className="sm">#{itemData?.driver1.no}</h3>
                </div>
                <div className="card__item-flag">
                  <div className="card__item-flag-inner">
                    <img
                      src={process.env.PUBLIC_URL + itemData?.driver1?.flag}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h3>{itemData?.driver1.name}</h3>
            </div>
          </div>
          <div className="card__row-image">
            <img src={process.env.PUBLIC_URL + "/images/vs.png"} alt="" />
          </div>
          <div
            className={
              "card__item " +
              (form?.[race?.round]?.filter((item) => item.id === itemData.id)
                .length > 0
                ? form?.[race?.round]?.filter(
                    (item) => item.id === itemData.id
                  )[0].driverChosen.code === itemData.driver2.code
                  ? "active"
                  : ""
                : "")
            }
            onClick={() =>
              !lock && parseInt(new Date() / 1000) < start
                ? pickHandler(itemData.driver2, false)
                : null
            }
          >
            <div className="card__item-inner">
              <div className="card__item-row">
                <div className="card__item-info">
                  <div className="card__item-info-image">
                    <img
                      src={process.env.PUBLIC_URL + "/images/helmet.png"}
                      alt=""
                    />
                  </div>
                  <h3 className="sm">#{itemData?.driver2?.no}</h3>
                </div>
                <div className="card__item-flag">
                  <div className="card__item-flag-inner">
                    <img
                      src={process.env.PUBLIC_URL + itemData?.driver2?.flag}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <h3>{itemData?.driver2?.name}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
