import React, { useContext, useState } from "react";
import { FirstContext } from "../../Context/FirstContext";
import PositionedSnackbar from "./PositionedSnackbar";
import SimpleBackdrop from "./SimpleBackdrop";
import NextGP from "./Tabs/NextGP";
import Historical from "./Tabs/Historical";

export default function Content({
  leaguePass,
  setModal,
  form,
  updateForm,
  race,
}) {
  const {
    openMessage,
    setOpenMessage,
    openSuccessMessage,
    setOpenSuccessMessage,
    openBackdrop,
    setOpenBackdrop,
    openErrorMessage,
    setOpenErrorMessage,
    activeButton,
    alreadyPlayed,
    start,
    end,
    bet,
  } = useContext(FirstContext);
  const [tab, setTab] = useState("next");
  return (
    <div
      className={"content " + (form?.[race?.round]?.length === 10 ? "" : "pb")}
    >
      <div className="content__inner">
        <div className="content__head">
          <div className="content__head-title">
            <h2>Clash of Champions</h2>
          </div>
          {leaguePass && (
            <div className="content__head-pass">
              <div className="content__head-pass-inner">League Pass</div>
            </div>
          )}
        </div>
        <div className="content__btns">
          <button
            type="button"
            className={"content__btn " + (tab === "next" ? "active" : "")}
            onClick={() => setTab("next")}
          >
            <div className="content__btn-inner">
              NEXT GP
              <span>{race?.where}</span>
            </div>
          </button>
          <button
            type="button"
            className={"content__btn " + (tab === "historical" ? "active" : "")}
            onClick={() => setTab("historical")}
          >
            <div className="content__btn-inner">Historical results</div>
          </button>
          <button
            type="button"
            className={"content__btn " + (tab === "leader" ? "active" : "")}
            // onClick={() => setTab("leader")}
          >
            <div className="content__btn-inner">Leaderboard</div>
          </button>
        </div>
        {tab === "next" && (
          <NextGP
            setModal={setModal}
            updateForm={updateForm}
            race={race}
            form={form}
          />
        )}
        {tab === "historical" && <Historical />}
      </div>
      {parseInt(new Date() / 1000) < start && activeButton && !alreadyPlayed
        ? form?.[race?.round]?.length === 10 && (
            <div className="content__validate">
              <p>
                You have completed <strong>10 steps out of 10 !</strong>
              </p>
              <div onClick={() => bet()} className="content__validate-btn">
                Validate your prediction
              </div>
            </div>
          )
        : null}

      <PositionedSnackbar
        open={openMessage}
        setOpen={setOpenMessage}
        message="WRONG NETWORK"
        vertical="top"
        horizontal="center"
        success={false}
      />

      <PositionedSnackbar
        open={openSuccessMessage}
        setOpen={setOpenSuccessMessage}
        message="SUCCESS"
        vertical="top"
        horizontal="center"
        success={true}
      />

      <PositionedSnackbar
        open={openErrorMessage}
        setOpen={setOpenErrorMessage}
        message="ERROR"
        vertical="top"
        horizontal="center"
        success={false}
      />

      <SimpleBackdrop open={openBackdrop} setOpen={setOpenBackdrop} />
    </div>
  );
}
