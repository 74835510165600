import React from "react";
import { closeIcon } from "../../Base/SVG";

export default function CongratsModal({ setModal, setLeaguePass }) {
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalCongrats">
        <div className="modalCongrats__title">
          Congratulations! <br /> You have the premium pass
        </div>
        <div className="modalCongrats__subtitle">LOREM IPSUM</div>
        <div className="modalCongrats__body">
          <div className="modalCongrats__card-outer">
            <div className="modalCongrats__card">
              <div className="modalCongrats__card-inner">
                <h5>F1 League</h5>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="modalCongrats__btn"
          onClick={() => {
            setLeaguePass(true);
            setModal(null);
          }}
        >
          Start the real adventure
        </button>
      </div>
    </>
  );
}
