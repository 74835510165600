import React, {useContext} from "react";
import { closeIcon } from "../../Base/SVG";
import { FirstContext } from "../../Context/FirstContext";


export default function ConnectModal({ setModal }) {
  const {connectWalletMainnet} = useContext(FirstContext);
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalConnect">
        <div className="modalConnect__title">Connect your wallet</div>
        <div className="modalConnect__subtitle">Get your league pass</div>
        <div className="modalConnect__card-outer">
          <div className="modalConnect__card">
            <div className="modalConnect__card-inner">
              <div className="modalConnect__card-inner-blur"></div>
              <h5>F1 League</h5>
            </div>
          </div>
        </div>
        {/* <div className="modalConnect__content">
          <h5>Lorem ispusm </h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Amet aenean id habitant eu
            scelerisque non leo vitae.
          </p>
        </div> */}
        <button
          type="button"
          className="modalConnect__btn"
          onClick={() => connectWalletMainnet()}
          // onClick={()=>connectWalletMainnet()}
        >
          Connect my wallet
        </button>
      </div>
    </>
  );
}
