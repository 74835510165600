import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FirstContext } from "../Context/FirstContext";

export default function Header({ menu, setMenu, setModal }) {
  const { initializeProvider, provider, signer, account, shortedAddress } =
    useContext(FirstContext);
  const [tab, setTab] = useState("sports");
  const [tooltip, setTooltip] = useState(false);
  return (
    <header className={"header " + (menu ? "active" : "")}>
      <div className="header__inner">
        <div className="header__inner-btns">
          <button
            type="button"
            className={
              "header__inner-btn " + (tab === "crypto" ? "active" : "")
            }
            onClick={() => {
              setTooltip(true);
              setTimeout(() => {
                setTooltip(false);
              }, 2000);
            }}
          >
            Crypto
            <div className={"tooltip " + (tooltip ? "active" : "")}>
              <p>Work in progress</p>
            </div>
          </button>
          <button
            type="button"
            className={
              "header__inner-btn " + (tab === "sports" ? "active" : "")
            }
            // onClick={() => setTab("sports")}
          >
            Sports
          </button>
        </div>
        <Link className="header__inner-logo">
          <img
            className="pc"
            src={process.env.PUBLIC_URL + "/images/logo.svg"}
            alt=""
          />
          <img
            className="mob"
            src={process.env.PUBLIC_URL + "/images/logo.svg"}
            alt=""
          />
        </Link>
        <div className="header__inner-more">
          <button
            type="button"
            className="header__inner-started"
            onClick={() => setModal("started")}
          >
            Getting started ?
          </button>
          <button
            type="button"
            className="header__inner-connect"
            onClick={() => initializeProvider()}
          >
            <div className="header__inner-connect-inner">
              {account ? shortedAddress(account) : "Connect my wallet"}
            </div>
          </button>
        </div>

        <div
          className={"burger " + (menu ? "active" : "")}
          id="menuBtn"
          onClick={() => {
            setMenu(!menu);
          }}
        >
          <span></span>
        </div>
      </div>
    </header>
  );
}
