export const EthereumMainnetTokens = {
    NATIVE: {
        address: "0x0000000000000000000000000000000000000000",
        decimals: 18,
        pricefeed: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
        feed: "ETH / USD",
        smallName: "ETH",
        image: "/images/chains/eth.svg",
    },
    USDC: {
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        decimals: 6,
        pricefeed: "0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6",
        feed: "USDC / USD",
        smallName: "USDC",
        image: "/images/chains/usd.svg",
    },
    USDT: {
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimals: 6,
        pricefeed: "0x3E7d1eAB13ad0104d2750B8863b489D65364e32D",
        feed: "USDT / USD",
        smallName: "USDT",
        image: "/images/chains/tether.svg",
    }
}

export const ArbitrumTokens = {
    NATIVE: {
        address: "0x0000000000000000000000000000000000000000",
        decimals: 18,
        pricefeed: "0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612",
        feed: "ETH / USD",
        smallName: "ETH",
        image: "/images/chains/eth.svg",
    },
    USDC: {
        address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
        decimals: 6,
        pricefeed: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
        feed: "USDC / USD",
        smallName: "USDC",
        image: "/images/chains/usd.svg"
    },
    USDT: {
        address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        decimals: 6,
        pricefeed: "0x3f3f5dF88dC9F13eac63DF89EC16ef6e7E25DdE7",
        feed: "USDT / USD",
        smallName: "USDT",
        image: "/images/chains/tether.svg",
    }
}

export const BaseTokens = {
    NATIVE: {
        address: "0x0000000000000000000000000000000000000000",
        decimals: 18,
        pricefeed: "0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70",
        feed: "ETH / USD",
        smallName: "ETH",
        image: "/images/chains/eth.svg",
    },
    USDC: {
        address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
        decimals: 6,
        pricefeed: "0x7e860098F58bBFC8648a4311b374B1D669a2bc6B",
        feed: "USDC / USD",
        smallName: "USDC",
        image: "/images/chains/usd.svg"
    }
}

export const PolygonTokens = {
    NATIVE: {
        address: "0x0000000000000000000000000000000000000000",
        decimals: 18,
        pricefeed: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
        feed: "MATIC / USD",
        smallName: "MATIC",
        image: "/images/chains/pol.svg"
    },
    USDC: {
        address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
        decimals: 6,
        pricefeed: "0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7",
        feed: "USDC / USD",
        smallName: "USDC",
        image: "/images/chains/usd.svg"
    },
    USDT: {
        address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        decimals: 6,
        pricefeed: "0x0A6513e40db6EB1b165753AD52E80663aeA50545",
        feed: "USDT / USD",
        smallName: "USDT",
        image: "/images/chains/tether.svg"
    }
}

export const chainData = [
    {  // ethereum
        tokens: [{
            address: "0x0000000000000000000000000000000000000000",
            decimals: 18,
            pricefeed: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
            feed: "ETH / USD",
            smallName: "ETH",
            value: "ETH",
            image: "/images/chains/eth.svg",
        }, {
            address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
            decimals: 6,
            pricefeed: "0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6",
            feed: "USDC / USD",
            smallName: "USDC",
            value: "USDC",
            image: "/images/chains/usd.svg",
        }, {
            address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            decimals: 6,
            pricefeed: "0x3E7d1eAB13ad0104d2750B8863b489D65364e32D",
            feed: "USDT / USD",
            smallName: "USDT",
            value: "USDT",
            image: "/images/chains/tether.svg",
        }],
        value: "Ethereum",
        image: "/images/chains/eth.svg",
        chainid: "0x1",
        multisig: "0xb2Ff840abB4511720Ec7C491fc105Be4180Ec33B"
    }, {   // arbitrum
        tokens: [{
            address: "0x0000000000000000000000000000000000000000",
            decimals: 18,
            pricefeed: "0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612",
            feed: "ETH / USD",
            smallName: "ETH",
            value: "ETH",
            image: "/images/chains/eth.svg",
        }, {
            address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
            decimals: 6,
            pricefeed: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
            feed: "USDC / USD",
            smallName: "USDC",
            value: "USDC",
            image: "/images/chains/usd.svg"
        },
            {
            address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
            decimals: 6,
            pricefeed: "0x3f3f5dF88dC9F13eac63DF89EC16ef6e7E25DdE7",
            feed: "USDT / USD",
            smallName: "USDT",
            value: "USDT",
            image: "/images/chains/tether.svg",
        }],
        value: "Arbritrum",
        image: "/images/chains/arb.svg",
        chainid: "0xa4b1",
        multisig: "0xb2Ff840abB4511720Ec7C491fc105Be4180Ec33B"
    }, { // base
        tokens: [{
            address: "0x0000000000000000000000000000000000000000",
            decimals: 18,
            pricefeed: "0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70",
            feed: "ETH / USD",
            smallName: "ETH",
            value: "ETH",
            image: "/images/chains/eth.svg",
        }, {
            address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
            decimals: 6,
            pricefeed: "0x7e860098F58bBFC8648a4311b374B1D669a2bc6B",
            value: "USDC",
            feed: "USDC / USD",
            smallName: "USDC",
            image: "/images/chains/usd.svg"
        }],
        value: "Base",
        image: "/images/chains/base.svg",
        chainid: "0x2105",
        multisig: "0xb2Ff840abB4511720Ec7C491fc105Be4180Ec33B"
    }, { // polygon
        tokens: [{
            address: "0x0000000000000000000000000000000000000000",
            decimals: 18,
            pricefeed: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
            feed: "MATIC / USD",
            smallName: "MATIC",
            value: "MATIC",
            image: "/images/chains/pol.svg"
        },{
            address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
            decimals: 6,
            pricefeed: "0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7",
            feed: "USDC / USD",
            smallName: "USDC",
            value: "USDC",
            image: "/images/chains/usd.svg"
        },{
            address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
            decimals: 6,
            pricefeed: "0x0A6513e40db6EB1b165753AD52E80663aeA50545",
            feed: "USDT / USD",
            smallName: "USDT",
            value: "USDT",
            image: "/images/chains/tether.svg"
        }],
        value: "Polygon",
        image: "/images/chains/pol.svg",
        chainid: "0x89",
        multisig: "0xb2Ff840abB4511720Ec7C491fc105Be4180Ec33B"
    } 
]