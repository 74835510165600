import React from "react";
import { closeIcon } from "../../Base/SVG";

export default function Tooltip({ setModal, setRewards }) {
  return (
    <>
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modal__inner-tooltip">
        <div className="modal__inner-tooltip-title">CLAIM YOUR REWARDS</div>
        <p>
          You can claim you rewards by clicking on the button below.
          <br />
          <br />
          Congratulations for your earnings!
        </p>
        <button
          className="button primary"
          onClick={() => {
            setRewards(false);
            setModal(false);
          }}
        >
          CLAIM
        </button>
      </div>
    </>
  );
}
